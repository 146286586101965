import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  engineer_issue_date: Yup.date().required("กรุณาระบุวันที่"),
  engineer_start_date: Yup.date().required("กรุณาระบุวันที่"),
  engineer_in_date: Yup.date().required("กรุณาระบุวันที่"),
  project_info: Yup.object().shape({
    contact_id: Yup.string().required("กรุณาระบุลูกค้า"),
  }),
});

export const initialValues = {
  engineer_issue_date: null,
  engineer_start_date: null,
  engineer_end_date: null,
  engineer_in_date: null,
  project_info: {
    fax: "",
    road: "",
    email: "",
    phone: "",
    tax_no: "",
    country: "",
    building: "",
    district: "",
    house_no: "",
    province: "",
    contact_id: "",
    project_document_id: "",
    project_id: "",
    village_no: "",
    postal_code: "",
    address_name: "",
    contact_name: "",
    project_name: "",
    sub_district: "",
  },
  reproduction: false,
  installation: false,
  adjustment: false,
  job_project_type: "",
  job_description: "",
  job_priority: "",
  delivery_method: "",
  delivery_count: 0,
  delivery_floor: 0,
  delivery_scaffold: "",
  delivery_cartage_method: [],
  engineer_list: [],
  engineer_status: "",
  engineer_remark: "",
  engineer_data: [
    {
      group_name: "",
      group_area: "",
      group_area_uom: "",
      category_list: [
        { category_name: "วัสดุไม้", item_data: [] },
        { category_name: "วัสดุประกอบ", item_data: [] },
        { category_name: "วัสดุอุปกรณ์หลัก", item_data: [] },
        { category_name: "วัสดุสิ้นเปลือง", item_data: [] },
        { category_name: "บริการ", item_data: [] },
        { category_name: "อื่นๆ", item_data: [] },
      ],
    },
  ],
  input_attachments: [],
  deliver_attachments: [],
  remark_attachments: [],
  revision_id: null,
  revision_name: "",
};
