import * as yup from "yup";

export const validationSchema = yup.object({});

export const initialValues = {
  sales_invoice_project_list: [],
  sales_invoice_document_id_list: [],
  billing_note_issue_date: null,
  billing_note_due_date: null,
  billing_info: {
    fax: "",
    road: "",
    email: "",
    phone: "",
    tax_no: "",
    country: "",
    building: "",
    district: "",
    house_no: "",
    province: "",
    contact_id: "",
    project_id: "",
    project_document_id: "",
    village_no: "",
    postal_code: "",
    address_name: "",
    contact_name: "",
    project_name: "",
    sub_district: "",
  },
  document_list: [],
  billing_note_status: "",
  billing_note_template_remark_id: "",
  billing_note_remark: "",
  vat_exempted_amount: 0,
  vat_0_amount: 0,
  vat_7_amount: 0,
  vat_amount: 0,
  net_amount: 0,
  withholding_tax: 0,
  total_amount: 0,
  sales_invoices: [],
};
